
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue';

import Store from '@/store';

import SVG from '@/components/SVG.vue';
import ProjectService from '@/services/project.service';
import Project from '@/models/project.model';

export default defineComponent({
	props: ['modelValue', 'label', 'placeholder', 'max', 'borderless', 'disabled'],

	components: {
		SVG,
	},

	setup(props, ctx) {
		// Input Element ref
		const input = ref(null as HTMLInputElement | null);
		// Container Element ref
		const container = ref(null as HTMLElement | null);

		const index = ref(0);
		const query = ref('');

		// Whether the modal is active or not
		const active = ref(false);

		const allProjects = ref([]);

		const maxIsExceeded = computed(() => props.max && (props.modelValue?.length || 0) >= props.max);

		// list of projects that are included in the search result
		const projects = computed(() => {
			if (!allProjects.value) {
				return [];
			}

			const lowerCaseQuery = query.value.toLowerCase();

			// filter out the projects that are already selected
			const projectNotSelected = allProjects.value.filter((project: Project) => {
				return !props.modelValue?.map((project: Project) => project.id).includes(project.id);
			});

			// filter out the projects that do not match the query
			return projectNotSelected.filter((project: Project) =>
				project.title.toLowerCase().includes(lowerCaseQuery)
			);
		});

		// Load all Projects when the component is mounted
		onMounted(() => {
			setProjects();
			loadProjects(props.modelValue);
		});

		// get the projects from the store when the projects change
		watch(Store.get('projects'), (value) => {
			allProjects.value = value;
		});

		watch(
			() => props.modelValue,
			(value) => {
				loadProjects(value);
			}
		);

		async function setProjects() {
			allProjects.value = Store.get('projects').value;

			if (!allProjects.value) {
				await ProjectService.get().then((response) => {
					if (response) {
						Store.set('projects', response);
					}
				});
			}
		}

		function loadProjects(data: number[]) {
			if (!data || !data.length) {
				return;
			}

			let projects: Project[] = [];

			for (const projectId of data) {
				if (isNaN(projectId)) {
					continue;
				}

				const project = allProjects.value.find((project: Project) => project.id == projectId);

				projects = [...new Set([...projects, project])] as Project[];

				ctx.emit('update:modelValue', projects);
			}
		}

		// add a project to the list
		function selectProject(project: string) {
			// if max is exceeded
			if (props.max && props.modelValue?.length >= props.max) {
				return;
			}

			if (!project) {
				return;
			}

			ctx.emit('update:modelValue', [...new Set([...(props.modelValue || []), project])]);

			query.value = '';
			index.value = 0;

			if (!input.value) {
				return;
			}

			// if 'max' is assigned and we have reached max
			if (props.max && props.modelValue?.length >= props.max) {
				input.value.blur();

				return;
			}

			input.value.focus();
		}

		function scroll() {
			nextTick(() => {
				const parent = container.value;

				if (!parent) {
					return;
				}

				// The active element
				const active: HTMLElement | null = parent.querySelector('[data-active="true"]');

				if (!active) {
					return;
				}

				const height = active.offsetHeight;
				const top = active.offsetTop;

				const parentHeight = parent.offsetHeight;
				const parentScrollTop = parent.scrollTop;

				if (top + height > parentScrollTop + parentHeight) {
					parent.scroll({ top: parentScrollTop + height });
				}

				if (top + height < parentScrollTop + height) {
					parent.scroll({ top: parentScrollTop - height });
				}
			});
		}

		function removeProject(projectToRemove: string) {
			ctx.emit(
				'update:modelValue',
				props.modelValue?.filter((project: string) => project !== projectToRemove)
			);
		}

		function selectProjectAtCurrentIndex() {
			selectProject(projects.value[index.value]);
		}

		function keydown(e: KeyboardEvent) {
			if (e.key == 'Backspace') {
				if (query.value != '') {
					return;
				}

				if (!props.modelValue) {
					return;
				}

				ctx.emit('update:modelValue', props.modelValue.slice(0, props.modelValue.length - 1));
			}
		}

		function up() {
			if (index.value <= 0) {
				return;
			}

			index.value--;

			scroll();
		}

		function down() {
			if (index.value >= projects.value?.length - 1) {
				return;
			}

			index.value++;

			scroll();
		}

		return {
			// data
			uid: getCurrentInstance()?.uid || '',
			input,
			container,
			query,
			index,
			active,

			// computed
			projects,
			maxIsExceeded,

			// functions
			selectProject,
			removeProject,
			selectProjectAtCurrentIndex,
			keydown,
			up,
			down,
		};
	},
});
