import axios from 'axios';

import Decision from '@/models/decision.model';

import CreateDecisionRequest from '@/requests/create-decision.request';
import UpdateDecisionRequest from '@/requests/update-decision.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class DecisionService {
	public static async get(meetingId: number): Promise<Decision[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/meeting/${meetingId}/decisions`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar avgerðirnar fyri fundin við id ${meetingId}`,
					error
				);

				return false;
			});
	}

	public static async create(createDecisionRequest: CreateDecisionRequest): Promise<Decision> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/decisions`, createDecisionRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna avgerðina', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateDecisionRequest: UpdateDecisionRequest): Promise<Decision> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/decisions/${id}`, updateDecisionRequest)
			.then((response) => {
				Toast.success('Dagfør avgerð', 'Avgerðin er dagførd');
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta avgerðina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/decisions/${id}`)
			.then((response) => {
				Toast.success('Avgerð strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika avgerðina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
