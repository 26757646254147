
import { computed, defineComponent, onMounted, reactive, watch, ref } from 'vue';
import _has from 'lodash/has';

import Store from '@/store';

import MeetingService from '@/services/meeting.service';

import SVG from '@/components/SVG.vue';
import Editor from '@/components/form/Editor.vue';
import UserInput from '@/components/form/UserInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Decisions from '@/components/meeting/Decisions.vue';
import FollowUps from '@/components/meeting/FollowUps.vue';
import ProjectInput from '@/components/form/ProjectInput.vue';
import User from '@/models/user.model';
import Meeting from '@/models/meeting.model';
import Decision from '@/models/decision.model';
import FollowUp from '@/models/follow-up.model';
import Project from '@/models/project.model';

interface Form {
	title: string;
	description: string;
	project: number[] | Project[];
	attendees: number[];
	date: Date | null;
	decisions: Decision[];
	followUps: FollowUp[];
}

export default defineComponent({
	props: ['active'],

	emits: ['update:active'],

	components: {
		SVG,
		Editor,
		UserInput,
		DateInput,
		Decisions,
		FollowUps,
		ProjectInput,
	},

	setup(props) {
		const meeting = Store.get('meeting');

		const contextMenu = reactive({
			active: false,
		});

		// The Form
		const form: Form = reactive({
			title: '',
			description: '',
			project: [],
			attendees: [],
			date: null,
			decisions: [],
			followUps: [],
		});

		const formIsDirty = ref(false);

		let canEdit = false;

		onMounted(() => {
			window.onbeforeunload = function () {
				if (formIsDirty.value) {
					return 'Ert tú vís/ur?';
				}
			};

			window.onkeydown = function (e: KeyboardEvent) {
				if (!props.active) {
					return;
				}

				if (e.ctrlKey && e.key == 's') {
					e.preventDefault();

					save();
				}
			};

			loadForm(meeting.value);
		});

		// "Updated At" text
		const lastUpdated = computed(() => {
			const updatedAt = getLatestUpdatedAt(meeting.value);
			const timeSince = updatedAt.diff(new Date());

			if (timeSince.minutes === 0) {
				return 'Júst nú';
			}

			if (timeSince.hours === 0) {
				return `${timeSince.minutes} ${timeSince.minutes > 1 ? 'minuttir' : 'minut'} síðani`;
			}

			if (timeSince.hours < 3) {
				return `${timeSince.hours} ${timeSince.hours > 1 ? 'tímar' : 'tími'} síðani`;
			}

			if (timeSince.days === 0) {
				return `Í dag kl. ${updatedAt.format('%H:%M')}`;
			}

			return `${updatedAt.format('%d. %mn3. %y kl. %H:%M')}`;
		});

		// Update the form when the Meeting changes value
		watch(meeting, (value) => {
			loadForm(value);
		});

		// Load the form data from the meeting
		function loadForm(data: Meeting) {
			if (!data) {
				return;
			}

			const attendees: number[] = [];

			if (data.users) {
				data.users.forEach((user: User) => {
					if (user.id) {
						attendees.push(user.id);
					}
				});
			}

			form.title = data.title;
			form.description = data.description || '';
			form.project = data.project_id ? [data.project_id] : [];
			form.attendees = attendees;
			form.date = data.scheduled_at ? new Date(data.scheduled_at) : null;
			form.decisions = data.decisions || [];
			form.followUps = data.follow_ups || [];
		}

		// Watch active state
		watch(
			() => props.active,
			async (value) => {
				if (value) {
					formIsDirty.value = false;

					setTimeout(() => {
						canEdit = true;
					}, 200);
				}

				// Save when closing the modal
				if (!value) {
					await save();

					canEdit = false;

					// Get the Meetings
					await MeetingService.get().then((meetings) => {
						if (meetings) {
							Store.set('meetings', meetings);
						}
					});
				}
			}
		);

		// Add/remove attendees on change
		watch(form, async (value) => {
			if (!canEdit) {
				return;
			}

			if (!formIsDirty.value) {
				formIsDirty.value = true;
			}

			meeting.value.title = value.title;
			meeting.value.project = value.project?.length ? value.project[0] : null;
			meeting.value.description = value.description;
			meeting.value.scheduled_at = value.date;

			// Create arrays of ids
			// const prevAttendees: number[] = meeting.value.users?.map((user: User) => user.id);
			const nextAttendees: number[] = [];

			value?.attendees?.forEach((userId: number) => userId && nextAttendees.push(userId));

			// Set to value after creating arrays of ids
			meeting.value.users = value.attendees;
		});

		function getLatestUpdatedAt(meeting: Meeting) {
			const dates = [];

			if (!meeting) {
				return new Date();
			}

			// Add meeting updated_at
			if (meeting.updated_at) {
				dates.push(new Date(meeting.updated_at).getTime());
			}

			// Add follow_ups updated_at dates
			if (meeting.follow_ups?.length) {
				meeting.follow_ups.forEach((follow_up) => {
					if (follow_up.updated_at) {
						dates.push(new Date(follow_up.updated_at).getTime());
					}
				});
			}

			// Add decisions updated_at dates
			if (meeting.decisions?.length) {
				meeting.decisions.forEach((decision) => {
					if (decision.updated_at) {
						dates.push(new Date(decision.updated_at).getTime());
					}
				});
			}

			// Return the latest date, converted back to a Date object
			return dates.length > 0 ? new Date(Math.max(...dates)) : new Date();
		}

		// Save the Form
		async function save() {
			if (!meeting.value?.id) {
				return;
			}

			if (!formIsDirty.value) {
				return;
			}

			let projectId = form.project.length && form.project[0];

			if (typeof projectId !== 'number' && _has(projectId, 'id')) {
				projectId = projectId.id;
			}

			await MeetingService.update(meeting.value.id, {
				title: form.title,
				description: form.description,
				project_id: projectId ? Number(projectId) : null,
				scheduled_at: form.date ? form.date.format('%y-%m-%d %H:%M:%S') : null,
			});

			formIsDirty.value = false;

			return;
		}

		async function remove() {
			await MeetingService.delete(meeting.value.id).then((response) => {
				if (response) {
					Store.set(
						'meetings',
						Store.get('meetings').value.filter((m: Meeting) => m.id != meeting.value.id)
					);
				}
			});
		}

		async function addUser(user: User) {
			if (!user?.id) {
				return;
			}

			await MeetingService.addAttendee(meeting.value.id, user.id);
		}

		async function removeUser(userId: number) {
			await MeetingService.removeAttendee(meeting.value.id, userId);
		}

		return {
			// data
			meeting,
			form,
			lastUpdated,
			contextMenu,

			// functions
			close,
			remove,
			addUser,
			removeUser,
		};
	},
});
