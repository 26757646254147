
import { computed, defineComponent, nextTick, Ref, ref } from 'vue';

import Store from '@/store';

import DecisionService from '@/services/decision.service';

import Decision from '@/components/meeting/Decision.vue';

export default defineComponent({
	components: {
		Decision,
	},

	setup() {
		const meeting = Store.get('meeting');

		const title = ref('');
		const input: Ref = ref(null);
		const isAdding = ref(false);

		const decisions = computed(() => meeting?.value?.decisions);

		function toggleAdding() {
			isAdding.value = true;

			nextTick(() => {
				input.value.focus();
			});
		}

		async function add() {
			await DecisionService.create({
				body: title.value,
				meeting_id: meeting.value.id,
			}).then((response) => {
				if (response) {
					title.value = '';

					if (!meeting.value.decisions) {
						meeting.value.decisions = [];
					}

					meeting.value.decisions = [...meeting.value.decisions, response];
				}
			});
		}

		return {
			// data
			input,
			isAdding,
			title,

			// computed
			decisions,

			// functions
			toggleAdding,
			add,
		};
	},
});
