
import { computed, defineComponent, nextTick, Ref, ref } from 'vue';

import Store from '@/store';

import FollowUp from '@/components/meeting/FollowUp.vue';
import FollowUpService from '@/services/follow-up.service';

export default defineComponent({
	props: ['project'],
	components: {
		FollowUp,
	},

	setup() {
		const meeting = Store.get('meeting');

		const description = ref('');
		const input: Ref = ref(null);
		const isAdding = ref(false);

		const followUps = computed(() => meeting?.value?.follow_ups);

		function toggleAdding() {
			isAdding.value = true;

			nextTick(() => {
				input.value.focus();
			});
		}

		async function add() {
			if (description.value.length === 0) {
				return;
			}

			await FollowUpService.create({
				meeting_id: meeting.value.id,
				description: description.value,
			}).then((response) => {
				if (response) {
					description.value = '';

					if (!meeting.value.follow_ups) {
						meeting.value.follow_ups = [];
					}

					meeting.value.follow_ups = [...meeting.value.follow_ups, response];
				}
			});
		}

		return {
			// data
			input,
			isAdding,
			description,

			// computed
			followUps,

			// functions
			toggleAdding,
			add,
		};
	},
});
