
import { computed, defineComponent, getCurrentInstance, nextTick, Ref, ref, watch } from 'vue';

import SVG from '@/components/SVG.vue';
import Task from '@/models/task.model';
import ProjectService from '@/services/project.service';

export default defineComponent({
	props: ['modelValue', 'showTitle', 'project'],

	emits: ['update'],

	components: {
		SVG,
	},

	setup(props, ctx) {
		// input element ref
		const input: Ref = ref(null);

		const query = ref('');
		const uid = getCurrentInstance()?.uid || '';

		const modalOpen = ref(false);
		const activeTask = ref();
		const projectTasks: Ref<Task[]> = ref([]);

		const currentProject: Ref<number | null> = ref(null);

		const disabled = ref(true);

		const index = ref(0);

		// get the users from the store on mounted
		watch(props, async (newValue) => {
			if (newValue.project === null && currentProject.value !== null) {
				disabled.value = true;
				projectTasks.value = [];
				activeTask.value = null;
				ctx.emit('update', null);

				return;
			}

			if (newValue?.project?.id && newValue.project.id !== currentProject.value) {
				currentProject.value = newValue.project.id;

				const project = await ProjectService.show(newValue.project.id);

				if (project && project?.tasks?.length) {
					projectTasks.value = project.tasks;
				}

				if (project && !project?.tasks?.length) {
					projectTasks.value = [];
				}

				load(props.modelValue);

				disabled.value = false;
			}
		});

		// Focus the search input when modal is activated
		watch(modalOpen, (value) => {
			if (!value) {
				return;
			}

			nextTick(() => {
				input.value.focus();
			});
		});

		// Loading users
		function load(task: Task) {
			if (!task) {
				activeTask.value = null;
				return;
			}

			if (!projectTasks.value) {
				return;
			}

			projectTasks.value.forEach((projectTask: Task) => {
				if (projectTask.id === task.id) {
					activeTask.value = projectTask;
				}
			});
		}

		// list of users that are included in the search result
		const tasks = computed(() => {
			if (!projectTasks.value) {
				return [];
			}

			if (!projectTasks.value) {
				return [];
			}

			const lowerCaseQuery = query.value.toLowerCase();

			// filter out the users that do not match the query
			return projectTasks.value.filter((task: Task) => task.title.toLowerCase().includes(lowerCaseQuery));
		});

		function closeModal() {
			query.value = '';
			index.value = 0;

			modalOpen.value = false;
		}

		// add a user to the list
		function selectTask(task: Task) {
			if (!task) {
				return;
			}

			activeTask.value = task;

			ctx.emit('update', task.id);

			closeModal();
		}

		function removeTask() {
			ctx.emit('update', null);

			activeTask.value = null;
		}

		function selectTaskAtCurrentIndex() {
			selectTask(tasks.value[index.value]);
		}

		function arrowUp() {
			if (index.value <= 0) {
				return;
			}

			index.value--;
		}

		function arrowDown() {
			if (index.value >= tasks.value?.length - 1) {
				return;
			}

			index.value++;
		}

		function handleModalClickAway(target: Element) {
			if (target.closest(`#add-task-${uid}`)) {
				return;
			}

			closeModal();
		}

		return {
			// data
			uid,
			input,
			query,
			index,
			activeTask,
			modalOpen,
			disabled,

			// computed
			tasks,

			// functions
			selectTask,
			closeModal,
			removeTask,
			selectTaskAtCurrentIndex,
			arrowUp,
			arrowDown,
			handleModalClickAway,
		};
	},
});
