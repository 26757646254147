
import { defineComponent, nextTick, reactive, ref } from 'vue';

import Store from '@/store';

import DecisionService from '@/services/decision.service';

import SVG from '@/components/SVG.vue';
import Decision from '@/models/decision.model';

export default defineComponent({
	props: ['decision'],

	components: {
		SVG,
	},

	setup(props) {
		const meeting = Store.get('meeting');

		const description = ref(props.decision.body);
		const editDescription = reactive({
			active: false,
		});
		const contextMenu = reactive({
			active: false,
		});

		async function remove() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			editDescription.active = false;

			meeting.value.decisions = meeting.value.decisions.filter(
				(decision: Decision) => decision.id != props.decision.id
			);

			await DecisionService.delete(props.decision.id);
		}

		async function updateDescription() {
			editDescription.active = false;

			if (description.value.length === 0) {
				return;
			}

			await DecisionService.update(props.decision.id, {
				body: description.value,
			}).then((response) => {
				if (response) {
					meeting.value.decisions.find((decision: Decision) => decision.id == props.decision.id).body =
						description.value;
				}
			});
		}

		function handleEditDescription(target: Element) {
			const currentDescription = editDescription.active;

			editDescription.active = true;

			if (!currentDescription) {
				nextTick(() => target.closest('.input-wrapper')?.querySelector('input')?.focus());
			}
		}

		return {
			// data
			contextMenu,
			description,
			editDescription,

			// functions
			remove,
			updateDescription,
			handleEditDescription,
		};
	},
});
