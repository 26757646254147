import axios from 'axios';

import FollowUp from '@/models/follow-up.model';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class FollowUpService {
	public static async get(meetingId: number): Promise<FollowUp[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/meetings/${meetingId}/follow-ups`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar uppfylgingarnar fyri fundin við id ${meetingId}`,
					error
				);

				return false;
			});
	}

	public static async show(id: number): Promise<FollowUp> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/follow-ups/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta uppfylgingina við id ${id}`, error);

				return false;
			});
	}

	public static async create(createFollowUpRequest: {
		meeting_id: number;
		description?: string;
		responsible_user_id?: number | null;
	}): Promise<FollowUp> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/follow-ups`, createFollowUpRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna uppfylgingina', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(
		id: number,
		updateFollowUpRequest: {
			due_at?: string | null;
			completed_at?: string | null;
			description?: string;
			responsible_user_id?: number | null;
			task_id?: number | null;
		}
	): Promise<FollowUp> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/follow-ups/${id}`, updateFollowUpRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta uppfylgingina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/follow-ups/${id}`)
			.then((response) => {
				Toast.success('Uppfylging strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika uppfylgingina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
