
import { defineComponent, ref, reactive, watch, nextTick } from 'vue';
import { DatePicker } from 'v-calendar';

import SVG from '@/components/SVG.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import UserInputSingle from '@/components/form/UserInputSingle.vue';
import TaskInputSingle from './TaskInputSingle.vue';
import Store from '@/store';

import FollowUpService from '@/services/follow-up.service';
import FollowUp from '@/models/follow-up.model';

export default defineComponent({
	props: ['followUp', 'project'],

	components: {
		DatePicker,
		SVG,
		Checkbox,
		UserInputSingle,
		TaskInputSingle,
	},

	setup(props) {
		const meeting = Store.get('meeting');

		const description = ref(props.followUp.description);
		const checked = ref(!!props.followUp.completed_at);
		const showUser = ref(!!props.followUp.responsible_user_id);
		const taskId = ref(props.followUp.task_id ? props.followUp.task_id : null);
		const task = ref(props.followUp.task_id ? props.followUp.task : null);
		const responsibleUser = ref(props.followUp.responsible_user_id ? props.followUp.responsible_user_id : []);
		const dueDate = ref(props.followUp.due_at ? new Date(props.followUp.due_at) : null);
		const formattedDueDate = ref('Eingin freist');

		const editDescription = reactive({
			active: false,
		});

		const contextMenu = reactive({
			active: false,
		});

		// Watch props
		watch(props, (propsValue) => {
			checked.value = !!propsValue.followUp.completed_at;

			if (responsibleUser.value !== propsValue.followUp.responsible_user_id) {
				responsibleUser.value = propsValue.followUp.responsible_user_id;
			}

			if (taskId.value !== propsValue.followUp.task_id) {
				task.value = propsValue.followUp.task;
				taskId.value = propsValue.followUp.task_id;
			}
		});

		// Update Meeting due_at when dueDate is changed
		watch(dueDate, async (value) => {
			await FollowUpService.update(props.followUp.id, {
				due_at: value ? value.format('%y-%m-%d') : null,
			}).then((response) => {
				if (response) {
					formattedDueDate.value = 'Eingin freist';

					if (value) {
						formattedDueDate.value = value.format('Freist: %-d. %mn3. %y');
					}

					meeting.value.follow_ups.find((followUp: FollowUp) => followUp.id == props.followUp.id).due_at =
						value;
				}
			});
		});

		async function remove() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await FollowUpService.delete(props.followUp.id).then((response) => {
				if (response) {
					meeting.value.follow_ups = meeting.value.follow_ups.filter(
						(followUp: FollowUp) => followUp.id != props.followUp.id
					);
				}
			});
		}

		// Update followup description
		async function updateDescription() {
			await FollowUpService.update(props.followUp.id, {
				description: description.value,
			}).then((response) => {
				if (response) {
					meeting.value.follow_ups.find(
						(followUp: FollowUp) => followUp.id == props.followUp.id
					).description = description.value;
				}
			});
		}

		async function updateCompleted(isChecked: boolean) {
			const completedAt = isChecked ? new Date().format('%y-%m-%d') : null;

			await FollowUpService.update(props.followUp.id, {
				completed_at: completedAt,
			}).then((response) => {
				if (response) {
					meeting.value.follow_ups.find(
						(followUp: FollowUp) => followUp.id == props.followUp.id
					).completed_at = completedAt;
				}
			});
		}

		function handleEditDescription(target: Element) {
			const currentDescription = editDescription.active;

			editDescription.active = true;
			if (!currentDescription) {
				nextTick(() => target.closest('.input-wrapper')?.querySelector('input')?.focus());
			}
		}

		async function handleResponsibleUpdate(userId: number | null) {
			await FollowUpService.update(props.followUp.id, {
				responsible_user_id: userId,
			}).then((response) => {
				if (response) {
					responsibleUser.value = userId;
					meeting.value.follow_ups.find(
						(followUp: FollowUp) => followUp.id == props.followUp.id
					).responsible_user_id = userId;
				}
			});
		}

		async function handleTaskUpdate(id: number | null) {
			await FollowUpService.update(props.followUp.id, {
				task_id: id,
			}).then((response) => {
				if (response) {
					taskId.value = id;
					task.value = response.task;

					meeting.value.follow_ups.find((followUp: FollowUp) => followUp.id == props.followUp.id).task_id =
						id;

					meeting.value.follow_ups.find((followUp: FollowUp) => followUp.id == props.followUp.id).task =
						response.task;
				}
			});
		}

		function addResponsibility() {
			showUser.value = true;
		}

		function handleClickAway() {
			if (responsibleUser.value.length === 0) {
				showUser.value = false;
			}
		}

		return {
			// data
			description,
			meeting,
			dueDate,
			formattedDueDate,
			contextMenu,
			showUser,
			responsibleUser,
			editDescription,
			checked,

			task,
			handleTaskUpdate,

			// functions
			remove,
			updateDescription,
			updateCompleted,
			handleEditDescription,
			handleResponsibleUpdate,
			addResponsibility,
			handleClickAway,
		};
	},
});
